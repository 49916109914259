import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import cpp from "../assets/language-icons/c++.png"
import python from "../assets/language-icons/267_Python-512.webp"
import c from "../assets/language-icons/c.png"

import {LanguageIconGridMember} from "../components/LanguageIconGridMember";

export function Skills() {
    return (
        <Box padding={2}>
            <Typography variant="h1" >Skills</Typography>
            <Grid>
                <Grid container alignItems={"center"}>
                    <Grid item padding={1}>
                        <Box alignItems={"center"}
                             component="img"
                            sx={{
                                maxHeight: { xs: 100, md: 100 },
                                maxWidth: { xs: 100, md: 100 },
                            }}
                             alt="c++ language icon"
                             src={cpp}
                        />
                    </Grid>
                        <Grid item padding={1}>
                            <Box alignItems={"center"}
                                 component="img"
                                 sx={{
                                     maxHeight: { xs: 100, md: 100 },
                                     maxWidth: { xs: 100, md: 100 },
                                 }}
                                 alt="python language icon"
                                 src={python}
                            />
                    </Grid>
                    <Grid>
                        <Grid item>
                            <Box alignItems={"center"}
                                 component="img"
                                 sx={{
                                     maxHeight: { xs: 100, md: 100 },
                                     maxWidth: { xs: 100, md: 100 },
                                 }}
                                 alt="python language icon"
                                 src={c}
                            />
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}