import {Fab, Stack} from "@mui/material";
import React from "react";
import {blueGrey} from "@mui/material/colors";
import {ProjectsProps} from "../models/ProjectsProps";

export default function Project_dropdown(props: ProjectsProps) {
    const [open, setOpen] = React.useState(false);

    const names = ["Project 1", "Project 2", "Project 3", "Project 4"]

    const name_dict = new Map([
        ["Project 1", "Mini Autonomous Car"],
        ["Project 2", "Web Crawler"],
        ["Project 3", "Gesture Controlled Car"],
        ["Project 4", "Digital Thermometer"]
    ])

    const colors = [blueGrey[50], blueGrey[100], blueGrey[200], blueGrey[300], blueGrey[400], blueGrey[500], blueGrey[600], blueGrey[700]];


    return (
        <Stack>
            <Fab onClick={() => {setOpen(!open)}} sx={{backgroundColor: props.color}} style={{boxShadow: "none"}} variant="extended" size="medium">
                {"Projects"}</Fab>

            {open &&
                <Stack direction="column" spacing={1} marginTop={1} alignItems="right">
                {names.map( (name, index) => {
                        return (
                                <Fab onClick={() => {props.sidebarProps.setClicked(name)}} sx={{backgroundColor: colors.at(-1*index - 4)}} style={{flex: "max-content", boxShadow: "none"}} variant="extended" size="small">
                                    {name_dict.get(name)}</Fab>
                        )
                })}</Stack>
            }
        </Stack>
    )
}
