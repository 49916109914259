import React, {useState} from 'react';
import './App.css';

import {Box, Grid} from "@mui/material";

import '@fontsource/dm-sans/300.css'
import '@fontsource/dm-sans/400.css'
import '@fontsource/dm-sans/500.css'
import '@fontsource/dm-sans/700.css'

import SidePanel from "./components/side-panel";

import Home from "./pages/home";
import {Skills} from "./pages/skills";
import Experience from "./pages/experience";
import Resume from "./pages/resume";
import Project1 from "./pages/project1";
import Project2 from "./pages/project2";
import Project3 from "./pages/project3";
import Project4 from "./pages/project4";

function App() {
    const [currentPage, setCurrentPage] = useState('Home')

    return (
        <Box padding={2}>
            <Grid container spacing={2}>
                <Grid item xs={3.5}>
                    <SidePanel setClicked={setCurrentPage}/>
                </Grid>
                <Grid item xs={.1}></Grid>
                <Grid item xs={8}>
                    {currentPage === 'Home' && <Home/>}
                    {currentPage === 'Skills' && <Skills/>}
                    {currentPage === 'Experience' && <Experience/>}
                    {currentPage === 'Project 1' && <Project1/>}
                    {currentPage === 'Project 2' && <Project2/>}
                    {currentPage === 'Project 3' && <Project3/>}
                    {currentPage === 'Project 4' && <Project4/>}
                    {currentPage === 'Resume' && <Resume/>}
                </Grid>
            </Grid>
        </Box>
    );
}

export default App;
