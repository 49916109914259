import {Box, Typography} from "@mui/material";
import web_search from "../assets/Web Crawler/web_search.png";
import React from "react";

export default function Project2() {
    return (
        <Box padding={2}>
            <Typography variant="h1" >Web Crawler</Typography>
            <Typography variant="h3" align="left">Premise</Typography>
            <Box alignItems={"center"}
                 component="img"
                 sx={{
                     maxHeight: { xs: 200, md: 200 },
                     height: "70%",
                     width: "70%"
                 }}
                 alt="screenshot of google search"
                 src={web_search}
            />

            <Typography variant="body1" align="left">
                - Loosely, when you make a Google search, Google parses HTML pages, associating words from the pages
                with whatever you searched.<br/>
                - The goal of this project was to replicate this functionality by creating a reverse index of pages to
                important words, then using this association to quickly serve search terms to a user.<br/>
                - The project was written entirely in C++.<br/>
            </Typography>
            <Typography variant="body1" align="left">
                With respect to the class that this project was implemented within, I am unable to provide code,
                but I would love to talk about my process, just ask!
            </Typography>
        </Box>
    )
}