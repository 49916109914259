import React from 'react';

import {Box, Stack, Fab} from "@mui/material";

import '@fontsource/dm-sans/300.css'
import '@fontsource/dm-sans/400.css'
import '@fontsource/dm-sans/500.css'
import '@fontsource/dm-sans/700.css'

import { deepPurple, pink } from '@mui/material/colors';

import {SidebarProps} from "../models/SidebarProps";
import Project_dropdown from "./project_dropdown";


export default function SidePanel(props: SidebarProps) {
    const names = ["Home", "Skills", "Experience", "Projects", "Contact", "Resume"]

    const colors = [pink[50], deepPurple[50], deepPurple[100], deepPurple[200], deepPurple[300], deepPurple[400], deepPurple[500], deepPurple[600], deepPurple[700]];
    return (
        <Box margin={1} marginTop={4}>
            <Stack direction="column" spacing={3}>
                {names.map( (name, index) => {
                    return (
                        name === "Projects" ?
                            <Project_dropdown sidebarProps={props} color={colors.at(-1*index - 4)}></Project_dropdown>
                            : name === "Resume" ?
                                <Fab href="../../public/alapati_kaeshev_ECSE_2026_resume.pdf" download="kaeshev_alapati_resume.pdf" sx={{backgroundColor: colors.at(-1 * index - 4)}} style={{boxShadow: "none"}}
                                     variant="extended" size="medium">
                                    {"Download Resume"}
                                </Fab>
                                :
                                <Fab onClick={() => {props.setClicked(name)}} sx={{backgroundColor: colors.at(-1*index - 4)}} style={{boxShadow: "none"}} variant="extended" size="medium">
                        {name}</Fab>
                     )
                })}
            </Stack>
        </Box>
    )
}