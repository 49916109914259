import {Box, Typography} from "@mui/material";

export default function Experience() {
    return (
        <Box padding={2}>
            <Typography variant="h1" >Experiences</Typography>
            <Typography variant="body1" align="left">
                <h2>DPR Construction: Data and Software Engineering Internship<br/></h2>
                <h4>Reston, VA / Remote: May 2024–August 2024<br/></h4>
                ● Developed 10+ access controlled REST-API endpoints for a Snowflake data warehouse supporting
                pagination and filtering features using Python FastAPI, fixing existing access control and searching
                issues for
                over 100 different endpoints.<br/>
                ● Implemented Python context managers with the Snowflake Connector, reducing code length by 25%.<br/>
                ● Produced a map view feature page for DPR’s incoming employee analytics platform in React TS displaying
                over 10,000 different past and active projects.<br/>
                ● Worked with Snowflake, Microsoft Azure, PostgreSQL, and other services to deploy full-stack
                deliverables ahead of schedule in a tightly deadlined environment.
                <br/>
                <br/>
                <h2>Lenovo: Product Management Internship<br/></h2>
                <h4>Apex, NC | June 2022–July 2022<br/></h4>
                ● Expedited development planning by a month through the quick analysis of 20,000 consumer review
                comments using Python Pandas, Java and statistical methods to identify keywords most commonly associated
                with review scores across different locations, easily identifying key feedback and performance areas
                within the product.<br/>
                <br/>
                <br/>
                <h2>Accenture: Machine Learning Intern<br/></h2>
                <h4>Durham, NC | June 2021–August 2021<br/></h4>
                ● Developed a reinforcement learning cost function in Python for a self-driving model car, reducing
                track turn misses by 75%.<br/>
                ● Reduced model errors and wasted training time by 50% by developing fully automated randomized unit
                tests while working as part of a three person unit, planning for and competing in an AWS DeepRacer
                competition.<br/>
            </Typography>
        </Box>
    )
}