import {Box, Typography} from "@mui/material";
import functional_diagram from "../assets/Digital Thermometer/functional_diagram.png";
import circuit from "../assets/Digital Thermometer/circuit.png";
import final_values from "../assets/Digital Thermometer/final_values.png";
import final_signal from "../assets/Digital Thermometer/final_signal.png";

import React from "react";

export default function Project4() {
    return (
        <Box padding={2}>
            <Typography variant="h1" >Digital Thermometer</Typography>
            <Typography variant="body1" align="left"></Typography>
            <Typography variant="h3" align="left">Premise</Typography>
            <Box alignItems={"center"}
                 component="img"
                 sx={{
                     maxHeight: {},
                     width: "70%"
                 }}
                 alt="functional diagram of "
                 src={functional_diagram}
            />
            <Typography variant="body1" align="left">
                - Prove the concept of a voltage divider in any circuit!<br/>
                - We decided to make a digital thermometer using a variable resistor..<br/>
            </Typography>

            <Typography variant="h3" align="left"><br></br>Design</Typography>
            <Box alignItems={"center"}
                 component="img"
                 sx={{
                     maxHeight: {},
                     width: "70%"
                 }}
                 alt="circuit diagram"
                 src={circuit}
            />
            <Typography variant="body1" align="left">
                Our design only had one primary stage.<br/>
                1) We found the regression curve for a thermistor from our kit-of-parts in order to create a transfer
                function between the voltage across the thermistor and the ambient temperature. Given this we are able to
                measure the temperature by back-solving from a measured voltage in our circuit!<br/>
            </Typography>

            <Typography variant="h5" align="left"><br></br>Considerations</Typography>
            <Typography variant="body1" align="left">
                - One of our unmet design considerations was to attempt to waterproof the circuit, but this would have
                required a PCB and shrink tubing around the thermistor.<br/>
            </Typography>

            <Typography variant="h3" align="left"><br></br>Conclusion!</Typography>

            <Box alignItems={"center"}
                 component="img"
                 sx={{
                     maxHeight: {},
                     width: "70%"
                 }}
                 alt="signal output representation"
                 src={final_signal}
            />

            <Typography variant="body1" align="left">
                The plot above is the voltage measured across the thermistor in orange, and the calculated temperature
                in the room in blue.<br/>
            </Typography>

            <Typography variant="body1" align="left">
                Comparing our final values from our circuit to measured ambient temperatures we calculated a final
                percent error of less than 2% for every ambient temperature that we tried!<br/>
            </Typography>

        </Box>
    )
}