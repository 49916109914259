import {Box, Typography} from "@mui/material";
import premise from "../assets/Mini Autonomous Car/premise.png";
import system_block_diagram from "../assets/Mini Autonomous Car/system_block_diagram.png"
import emconn_results_final from "../assets/Mini Autonomous Car/emcon_results_final.png"
import React from "react";

export default function Project1() {
    return (
        <Box padding={2}>
            <Typography variant="h1">Mini Autonomous Car</Typography>
            <Typography variant="h3" align="left">Premise</Typography>
            <Box alignItems={"center"}
                 component="img"
                 sx={{
                     maxHeight: { xs: 200, md: 200 },
                     height: "70%",
                     width: "70%"
                 }}
                 alt="drawing of project premise"
                 src={premise}
            />
            <Typography variant="body1" align="left">
                - We have a negative parabolic shaped track and <Typography fontWeight={"bold"}>we want the car to stop at the very top</Typography>.<br/>
                - Our only sensor input was a 3 axis accelerometer.<br/>
                - We were only allowed to control the car using 1 or
                more PID control systems!
            </Typography>

            <Typography variant="h3" align="left"><br></br>Design</Typography>
            <Box alignItems={"center"}
                 component="img"
                 sx={{
                     maxHeight: { xs: 200, md: 200 },
                     height: "70%",
                     width: "70%"
                 }}
                 alt="drawing of project premise"
                 src={system_block_diagram}
            />
            <Typography variant="body1" align="left">
                Our design mainly consisted of 2 stages.<br/>
                1) A PI control system capable of maintaining constant speed for the car despite persistent errors.<br/>
                2) A PID control system tuned to halt the car when a peak is detected.
            </Typography>
            <Typography variant="h5" align="left"><br></br>Considerations</Typography>
            <Typography variant="body1" align="left">
                - One of the main issues with the car's peak detection was that any sudden change in acceleration would
                register to the accelerometer as a force, or change in slope, that we had to account for in our PID control system.<br/>
                - One of the best solutions to this was to slow the car's movement down as much as possible in order to keep lurches to a minimum.<br/>
            </Typography>

            <Typography variant="h3" align="left"><br></br>Conclusion!</Typography>
            <Box alignItems={"center"}
                 component="img"
                 sx={{
                     width: "100%"
                 }}
                 alt="graph of "
                 src={emconn_results_final}
            />
            <Typography variant="body1" align="left">
                The graph shows the output of the PI and PID control systems which output PWM (duty percent) values to
                motors on the mini-car, we can see an almost direct relationship between the angle of the car and the
                 requested duty percent.<br/>
                The right side of the graph represents what happens as the car approaches a 0 degree angle, we can see
                that the duty percentage requested drops to 0.
            </Typography>
        </Box>
    )
}